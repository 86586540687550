import React from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Container from "@mui/material/Container";
import Box from "common/components/Box";
import Heading from "common/components/Heading";
import Text from "common/components/Text";
import AgendaStepper from "components/AgendaStepper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "50%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

export default () => {
  const classes = useStyles();
  return (
    <Container {...classes} maxWidth="md" id="agenda">
      <Box {...secTitleWrapper}>
        <Text {...secText} content="AGENDAR" />
        <Heading {...secHeading} content="Agenda con nostros en segundos" />
      </Box>
      <AgendaStepper />
    </Container>
  );
};

const secTitleWrapper = {
  mb: ["40px", "70px"],
};
const secText = {
  as: "span",
  display: "block",
  textAlign: "center",
  fontSize: "22px",
  letterSpacing: "0.15em",
  fontWeight: "700",
  color: "#2aa275",
  mb: "5px",
};
const secHeading = {
  textAlign: "center",
  fontSize: ["20px", "24px"],
  fontWeight: "500",
  color: "#0f2137",
  letterSpacing: "-0.025em",
  mb: "0",
  lineHeight: "1.67",
};
