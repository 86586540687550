import React, { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { FormProvider, useForm } from "react-hook-form";
import { AgendaServices } from "./AgendaDivisions";
import { AngendaLocation } from "./AgendaProductAndLocation";
import { AgendaAvailability } from "./AgendaAvailability";
import { AgendaDatosPaciente } from "./AgendaDatosPaciente";

function getSteps() {
  return ["", "", "", ""];
}

export type AgendaFormData = {
  division: string;
  product: {
    id: string;
    label: string;
  };
  timeSlot: string;
  date: Dayjs;
  number_of_people: string;
  patients: {
    given_name: string;
    family_name: string;
    dni: string;
    dni_type: string;
    email: string;
    phone_number: string;
  }[];
  comuna?: string;
  address?: string;
};

export default () => {
  const [activeStep, setActiveStep] = useState(0);
  /* -------------------------------------------------------------------------- */
  /*                               React Hook Form                              */
  /* -------------------------------------------------------------------------- */

  const methods = useForm<AgendaFormData>({
    defaultValues: {
      date: dayjs(),
      number_of_people: "1",
    },
  });
  const steps = getSteps();

  const selectService = (id: string) => {
    methods.setValue("division", id);
    handleNext();
  };

  const handleSelectTimeSlot = (value: string) => {
    methods.setValue("timeSlot", value);
    handleNext();
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <AgendaServices handleClick={selectService} />;
      case 1:
        return (
          <AngendaLocation
            handleBackClick={handleBack}
            handleNextClick={handleNext}
          />
        );
      case 2:
        return <AgendaAvailability handleSlotClick={handleSelectTimeSlot} />;
      case 3:
        return <AgendaDatosPaciente handleStartOver={handleReset} />;
      default:
        return "Unknown step";
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 1) {
      methods.reset();
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    methods.reset();
  };

  return (
    <form>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};
          return (
            <Step key={`step-${index}`} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div style={{ minHeight: 200 }}>
        <FormProvider {...methods}>{getStepContent(activeStep)}</FormProvider>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Button
          variant="contained"
          onClick={handleBack}
          disabled={activeStep === 0}
        >
          Atrás
        </Button>
      </div>

      {/* <div style={{ marginTop: "20px" }}>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleNext}
                  color={
                    activeStep === steps.length - 1 ? "secondary" : "primary"
                  }
                >
                  {activeStep === steps.length - 1 ? "Terminar" : "Siguiente"}
                </Button>
              </div> */}
    </form>
  );
};
