import React, { Fragment, useEffect, useState } from "react";
import { Grid, MenuItem, TextField, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Controller, useFormContext } from "react-hook-form";
import NumberFormat from "react-number-format";
import {
  validate as validateRUT,
  clean as cleanRUT,
  format as formatRUT,
} from "rut.js";
import Swal from "sweetalert2";

import {
  // connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import { AgendaFormData } from ".";
import firebase from "gatsby-plugin-firebase-v9.0";

interface BookAppointmentFormData extends AgendaFormData {
  pay_now: boolean;
}

export const AgendaDatosPaciente = (props: { handleStartOver: Function }) => {
  const theme = useTheme();
  const [sendingBookingRequest, setSendingBookingRequest] = useState(false);

  const validateRut = (rut: string | undefined | null, dni_type: string) => {
    if (rut) {
      if (dni_type === "RUT") {
        return validateRUT(cleanRUT(rut)) || "RUT Inválido";
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                                  Firebase                                  */
  /* -------------------------------------------------------------------------- */
  const functions = getFunctions(firebase, "southamerica-east1");
  const bookAppointment = httpsCallable<
    BookAppointmentFormData,
    { instructions: string; mercadopagoURL: string; appointmentID: string }
  >(functions, "bookAppointment");
  // connectFunctionsEmulator(functions, "localhost", 5001);
  /* -------------------------------------------------------------------------- */
  /*                               React Hook Form                              */
  /* -------------------------------------------------------------------------- */

  const { control, handleSubmit, watch } = useFormContext<AgendaFormData>();

  /* -------------------------------------------------------------------------- */
  /*                                 MercadoPago                                */
  /* -------------------------------------------------------------------------- */

  const number_of_people = parseInt(watch("number_of_people"));

  return (
    <Grid container direction="row" justifyContent="center" spacing={3}>
      {Array.apply(null, Array(number_of_people)).map((e, index: number) => {
        const dni_type = watch(`patients.${index}.dni_type`);
        return (
          <Fragment key={`patient-${index}`}>
            <Grid item xs={12}>
              <h1>Paciente {index + 1}</h1>
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name={`patients.${index}.given_name`}
                defaultValue=""
                rules={{ required: "Debe rellenar campo" }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    {...field}
                    label="Nombres"
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name={`patients.${index}.family_name`}
                defaultValue=""
                rules={{ required: "Debe rellenar campo" }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    {...field}
                    label="Apellidos"
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name={`patients.${index}.dni_type`}
                defaultValue="RUT"
                render={({ field, fieldState: { error } }) => (
                  <TextField fullWidth select label="Tipo de ID" {...field}>
                    {[
                      { value: "RUT", label: "RUT" },
                      {
                        value: "passport",
                        label: "Pasaporte",
                      },
                    ].map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name={`patients.${index}.dni`}
                defaultValue=""
                rules={{
                  required: "Debe rellenar campo",
                  validate: (rut) => validateRut(rut, dni_type),
                }}
                render={({ field, fieldState: { error } }) =>
                  dni_type === "passport" ? (
                    <TextField
                      fullWidth
                      label="Pasaporte"
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      label="RUT"
                      {...field}
                      value={field.value ? formatRUT(field.value) : ""}
                      onChange={(e) =>
                        field.onChange(formatRUT(e.target.value))
                      }
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name={`patients.${index}.email`}
                defaultValue=""
                rules={{
                  required: "Debe rellenar campo",
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Debe ser email válido",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    {...field}
                    label="Email"
                    type="email"
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name={`patients.${index}.phone_number`}
                rules={{
                  required: "Debe rellenar campo",
                  pattern: {
                    value: /^\+?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/,
                    message: "Debe ser celular válido",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <NumberFormat
                    customInput={TextField}
                    fullWidth
                    error={!!error}
                    helperText={error ? error.message : null}
                    label="Celular"
                    format="+569 #### ####"
                    allowEmptyFormatting
                    //@ts-ignore
                    value={field.value}
                    type="tel"
                    onValueChange={(v) => field.onChange(v.formattedValue)}
                  />
                )}
              />
            </Grid>
          </Fragment>
        );
      })}

      {/* <Grid item md={12} textAlign="center">
        <LoadingButton
          variant="contained"
          color="success"
          onClick={() => checkout.open()}
        >
          A pago
        </LoadingButton>
      </Grid> */}
      <Grid item md={12} textAlign="center">
        <LoadingButton
          variant="contained"
          color="success"
          loading={sendingBookingRequest}
          onClick={handleSubmit(async (data: AgendaFormData) => {
            try {
              Swal.fire({
                title: "Confirmar datos",
                icon: "warning",
                showDenyButton: true,
                confirmButtonText: "Pagar ahora",
                confirmButtonColor: theme.palette.success.main,
                denyButtonText: "Pagar al momento de la cita",
                denyButtonColor: theme.palette.primary.main,
                preConfirm: async () => {
                  const appointment = await bookAppointment({
                    ...data,
                    //@ts-ignore
                    date: data.date.format("YYYY-MM-DD"),
                    pay_now: true,
                  });

                  return appointment.data;
                },
                preDeny: async () => {
                  const appointment = await bookAppointment({
                    ...data,
                    //@ts-ignore
                    date: data.date.format("YYYY-MM-DD"),
                    pay_now: false,
                  });

                  return appointment.data;
                },
              })
                .then((result) => {
                  if (result.isConfirmed) {
                    if (result.value.mercadopagoURL)
                      window.location.replace(result.value.mercadopagoURL);
                  }
                  if (result.isDenied) {
                    Swal.fire({
                      title: "Cita confirmada",
                      icon: "success",
                      html: `
                    <b>Código cita:</b> ${result.value.appointmentID}
                    <p>Antes de su cita siga las siguientes indicaciones:</p>
                    <b>${result.value.instructions}</b>
                    `,
                    });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    title: "Error al agendar su cita",
                    icon: "error",
                    text: "Por favor intente más tarde",
                  });
                });

              // props.handleStartOver();
            } catch (error) {
              setSendingBookingRequest(false);
              Swal.fire(
                "Oh oh!",
                "Hubo un error al agendar su cita. Por favor intente más tarde o contáctenos por teléfono, whatsapp o email",
                "error"
              );
            }
          })}
        >
          Enviar
        </LoadingButton>
      </Grid>
      {/* <Grid item md={12} textAlign="center">
        <LoadingButton
          variant="contained"
          color="success"
          onClick={handleSubmit((data) => console.log(data))}
        >
          LOG
        </LoadingButton>
      </Grid> */}
    </Grid>
  );
};
