import React, { useEffect, useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StaticDatePicker from "@mui/lab/StaticDatePicker";
import es from "dayjs/locale/es";
import dayjs, { Dayjs } from "dayjs";
import Loader from "common/components/Loader";
import { Controller, useFormContext } from "react-hook-form";
import {
  // connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import firebase from "gatsby-plugin-firebase-v9.0";

interface AgendaAvailabilityProps {
  handleSlotClick: (value: string) => void;
}
export const AgendaAvailability = (props: AgendaAvailabilityProps) => {
  /* -------------------------------------------------------------------------- */
  /*                                  Firebase                                  */
  /* -------------------------------------------------------------------------- */
  const functions = getFunctions(firebase, "southamerica-east1");
  const getAvailability = httpsCallable<
    { date: string; division: string; productID: string },
    string[]
  >(functions, "getAvailability");
  // connectFunctionsEmulator(functions, "localhost", 5001);
  /* -------------------------------------------------------------------------- */
  /*                                   States                                   */
  /* -------------------------------------------------------------------------- */
  const [loadingAvailability, setLoadingAvailability] = useState(false);
  const [availability, setAvailability] = useState<string[]>([]);

  /* -------------------------------------------------------------------------- */
  /*                               React Hook Form                              */
  /* -------------------------------------------------------------------------- */
  const { control, watch } = useFormContext();
  const currentDate = watch("date");
  const selectedDivision = watch("division");
  const product = watch("product");

  const disableWeekends = (date: Dayjs) => {
    return date.day() === 0;
  };

  useEffect(() => {
    fetchAvailability(currentDate);
  }, []);

  const fetchAvailability = async (date: Dayjs) => {
    setLoadingAvailability(true);
    const availabilityArray = await getAvailability({
      date: date.format("YYYY-MM-DD"),
      division: selectedDivision,
      productID: product.id,
    });
    setAvailability(availabilityArray.data);
    setLoadingAvailability(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale={es}>
      <Grid
        container
        spacing={1}
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        style={{ minHeight: "300px" }}
      >
        <Grid item md={6} xs={12} maxHeight="300px">
          <Controller
            control={control}
            name="date"
            render={({ field: { onChange, ...controllerParams } }) => (
              <StaticDatePicker<Date>
                {...controllerParams}
                orientation="portrait"
                displayStaticWrapperAs="desktop"
                openTo="day"
                //@ts-expect-error
                minDate={dayjs()}
                //@ts-expect-errorz
                shouldDisableDate={disableWeekends}
                onChange={(newValue) => {
                  onChange(newValue);
                  //@ts-ignore
                  fetchAvailability(newValue);
                }}
                toolbarTitle="Seleccionar Fecha"
                renderInput={(params) => <TextField {...params} />}
              />
            )}
          />
        </Grid>
        {loadingAvailability ? (
          <Grid item direction="row" md={4} xs={12}>
            {/* @ts-ignore */}
            <Loader />
          </Grid>
        ) : (
          <Grid item container spacing={3} direction="row" md={4} xs={12}>
            {availability.length === 0 ? (
              <>No se encuentran horas disponibles</>
            ) : (
              availability.map((timeSlot, index) =>
                timeSlot !== "Slots Not Available" ? (
                  <Grid item key={`availability${index}`} xs={4}>
                    <Button
                      variant="outlined"
                      onClick={() => props.handleSlotClick(timeSlot)}
                    >
                      {timeSlot}
                    </Button>
                  </Grid>
                ) : (
                  <>No se encuentran horas disponibles</>
                )
              )
            )}
          </Grid>
        )}
      </Grid>
    </LocalizationProvider>
  );
};
