import React from "react";
import {
  Box,
  Button,
  ButtonBase,
  Container,
  Grid,
  Icon,
  styled,
  SvgIcon,
  Typography,
} from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";

export const AgendaServices = ({ handleClick }: { handleClick: Function }) => {
  const Data = useStaticQuery(graphql`
    query {
      landingJson {
        DIVISIONS {
          title
          icon {
            publicURL
          }
          id
        }
      }
    }
  `);

  return (
    <Grid
      container
      spacing={3}
      alignItems="center"
      justifyContent="center"
      marginTop="30px"
    >
      {Data.landingJson.DIVISIONS.map((division) => (
        <Grid item xs={12} sm={6} textAlign="center">
          <Button
            variant={"contained"}
            color={"primary"}
            style={{
              width: "200px",
              minHeight: "180px",
              // justifySelf: "stretch",
              // margin: "1vh",
              backgroundImage: `url(${division.icon.publicURL})`,
              backgroundSize: `100% 100%`,
            }}
            onClick={() => handleClick(division.id)}
          >
            <Typography
              variant={"button"}
              style={{ fontSize: 24, paddingRight: 10 }}
            >
              {division.title}
            </Typography>
            {/* <SvgIcon style={{ position: "absolute", bottom: 5, right: 5 }} /> */}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};
