import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import firebase from "gatsby-plugin-firebase-v9.0";

interface AgendaLocationProps {
  handleBackClick: Function;
  handleNextClick: Function;
}
export const AngendaLocation = (props: AgendaLocationProps) => {
  const [open, setOpen] = useState(false);
  const [selectedDomicilio, setSelectedDomicilio] = useState(false);
  const [productOptions, setProductOptions] = useState<
    { id: string; description: string; category: string }[]
  >([]);
  const loading = open && productOptions.length === 0;
  const [locations, setLocations] = useState([]);
  /* -------------------------------------------------------------------------- */
  /*                                  Firebase                                  */
  /* -------------------------------------------------------------------------- */
  const functions = getFunctions(firebase, "southamerica-east1");
  const getProducts = httpsCallable<
    unknown,
    { id: string; description: string }[]
  >(functions, "getProducts");
  // connectFunctionsEmulator(functions, "localhost", 5001);

  /* -------------------------------------------------------------------------- */
  /*                               React Hook Form                              */
  /* -------------------------------------------------------------------------- */
  const { control, watch, handleSubmit } = useFormContext();
  const selectedDivision = watch("division");
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const products = await getProducts({ division: selectedDivision });
      if (active) {
        setProductOptions(
          products.data.map((product) => ({
            ...product,
            category:
              selectedDivision === "ecografia"
                ? "Ecografía"
                : ["pcr_covid", "antigeno_covid"].includes(product.id)
                ? "COVID"
                : "Laboratorio",
          }))
        );
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setProductOptions([]);
    }
  }, [open]);

  useEffect(() => {
    const possibleLocations = [
      {
        id: "maipu",
        label: "Maipú - Pasaje Los Universitarios 2124",
      },
    ];
    setLocations(possibleLocations);
  }, []);

  return (
    <Grid
      container
      direction="row"
      spacing={4}
      justifyContent="center"
      alignItems="center"
      minHeight="200px"
      marginTop="20px"
    >
      <Grid item md={4} xs={6}>
        <Controller
          control={control}
          name="product"
          rules={{
            required: "Debe seleccionar un servicio",
          }}
          render={({
            field: { onChange, ...controllerParams },
            fieldState: { error },
          }) => (
            <Autocomplete
              {...controllerParams}
              fullWidth
              disablePortal
              options={productOptions.sort(
                (a, b) => -b.category.localeCompare(a.category)
              )}
              getOptionLabel={(option) => option.description}
              loading={loading}
              loadingText="Cargando..."
              open={open}
              groupBy={(option) => option.category}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              onChange={(e, data) => {
                onChange(data);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Servicio"
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          )}
        />
      </Grid>
      {/* {selectedDivision !== "ecografia" && (
        <Grid item md={2} xs={6}>
          <FormGroup>
            <FormControlLabel
              control={<Switch />}
              label="Domicilio"
              disabled={selectedDivision === "ecografia"}
              value={selectedDomicilio}
              onChange={() => setSelectedDomicilio(!selectedDomicilio)}
            />
          </FormGroup>
        </Grid>
      )} */}
      {!selectedDomicilio && locations.length > 0 && (
        <Grid item md={6} xs={12}>
          <Controller
            control={control}
            name="location"
            defaultValue={locations[0]}
            render={({ field: { onChange, ...controllerParams } }) => (
              <Autocomplete
                {...controllerParams}
                fullWidth
                disablePortal
                disabled
                options={locations}
                onChange={(e, data) => onChange(data)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField {...params} label="Ubicación" />
                )}
              />
            )}
          />
        </Grid>
      )}
      {selectedDomicilio && (
        <>
          <Grid item md={6} xs={12}>
            <Controller
              control={control}
              name="number_of_people"
              rules={{ required: "Debe rellenar campo" }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  {...field}
                  label="Número de pacientes"
                  type="number"
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputProps={{ type: "number" }}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              control={control}
              name="comuna"
              rules={{ required: "Debe seleccionar comuna" }}
              render={({
                field: { onChange, ...controllerParams },
                fieldState: { error },
              }) => (
                <Autocomplete
                  {...controllerParams}
                  fullWidth
                  disablePortal
                  options={comunas}
                  onChange={(e, data) => onChange(data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Comuna"
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              control={control}
              name="address"
              rules={{ required: "Debe rellenar campo" }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  {...field}
                  label="Dirección"
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
        </>
      )}
      <Grid item textAlign="center" xs={12} marginTop="10px">
        Informamos que ya no hacemos exámenes a domicilio. Gracias por su
        comprensión.
      </Grid>
      <Grid item textAlign="center" xs={12} marginTop="10px">
        <Button
          variant="contained"
          onClick={handleSubmit(() => props.handleNextClick())}
        >
          Siguiente
        </Button>
      </Grid>
    </Grid>
  );
};

const comunas = [
  "Cerrillos",
  "Cerro Navia",
  "Conchalí",
  "Huechuraba",
  "Independencia",
  "La Florida",
  "La Reina",
  "Las Condes",
  "Lo Barnechea (excepto El Arrayán)",
  "Lo Prado",
  "Maipú",
  "Ñuñoa",
  "Providencia",
  "Pudahuel",
  "Quilicura",
  "Quinta Normal",
  "Recoleta",
  "San Joaquín",
  "San Miguel",
  "Santiago Centro",
  "Vitacura",
];
