import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Container from "common/components/UI/Container";
import Image from "common/components/Image";
import Box from "common/components/Box";
import Heading from "common/components/Heading";

interface IInstitucionesProps {
  secTitleWrapper?: string;
  secText?: string;
  secHeading?: string;
}

const InstitucionesSection = ({
  secText,
  secHeading,
  secTitleWrapper,
}: IInstitucionesProps) => {
  const data = useStaticQuery(graphql`
    query {
      landingJson {
        CLIENTES {
          id
          name
          logo {
            childImageSharp {
              fluid(maxHeight: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  return (
    <Section>
      {data?.landingJson?.CLIENTES?.map((client) => (
        <LogoWrapper>
          <Image
            key={client.id}
            src={client.logo.childImageSharp.fluid.src}
            alt={client.name}
          />
        </LogoWrapper>
      ))}
    </Section>
  );
};

export default InstitucionesSection;

const Section = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 1rem 0.5rem;
  max-width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 768px) {
    padding: 60px 0 50px;
  }
  @media only screen and (max-width: 480px) {
    padding: 50px 0 40px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex: ${(props) => props.size};
  @media only screen and (max-width: 768px) {
    img {
      margin-right: 60px;
      margin: 1rem 1rem;
    }
  }
  @media only screen and (max-width: 480px) {
    img {
      margin-right: 30px;
    }
  }
`;
