import React, { Fragment, useEffect } from "react";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import Sticky from "react-stickynode";
import { DrawerProvider } from "common/contexts/DrawerContext";
// import { saasModernTheme } from "common/theme/saasModern";
import { ResetCSS } from "common/assets/css/style";
import {
  GlobalStyle,
  ContentWrapper,
} from "containers/Landing/sassModern.style";

import BannerSection from "containers/Landing/Banner";
import Navbar from "containers/Landing/Navbar";
import QuienesSomosSection from "containers/Landing/QuienesSomos";
// import ClientesSection from "containers/Landing/Clientes";
// import InfoSection from "containers/Landing/Info";
import ServiciosSection from "containers/Landing/Servicios";
// import TestimonialSection from "containers/Landing/Testimonial";
import Footer from "containers/Landing/Footer";
import SEO from "components/seo";

// import TrialSection from "containers/Landing/Trial";
// import FaqSection from "containers/Landing/Faq";
// import PricingSection from "containers/Laboratorio/Pricing";
// import UpdateScreen from "containers/Landing/UpdateScreen";
import InstitucionesSection from "containers/Landing/Clientes/instituciones";
import AgendaStepper from "containers/AgendaStepper";
import { theme } from "../theme";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.

export default () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO title="MiDiagnóstico | Servicios Médicos" />

          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            <BannerSection />
            <QuienesSomosSection
              processImageStyle={{
                display: "block",
                width: "50%",
                alignSelf: "center",
              }}
            />

            <AgendaStepper />
            {/* <InfoSection /> */}
            <ServiciosSection
              iconStyle={{
                marginRight: "auto",
                marginLeft: "auto",
                width: 120,
                height: 120,
                marginBottom: 10,
              }}
              contentStyle={{
                textAlign: "center",
              }}
            />
            {/* <UpdateScreen /> */}
            {/* <PricingSection /> */}
            {/* <ClientesSection /> */}
            <InstitucionesSection />
            {/* <TestimonialSection /> */}
            {/* <FaqSection /> */}
            {/* <TrialSection /> */}
            {/* <ContactoSection /> */}
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
